/* 
  Class to be applied on as most-outer as possible components to work in
  CSS border-box manner without introducing UI regressions in other parts of the application
*/
.jugl__border-box-component,
.jugl__border-box-component * {
  box-sizing: border-box;
}

/* 
  Hide container's scrollbar
*/

/* Chrome, Safari, Opera, and other WebKit-based browsers */
.jugl__hidden-scrollbar::-webkit-scrollbar {
  display: none;
}

.jugl__hidden-scrollbar {
  /* IE (Internet Explorer) and Edge */
  -ms-overflow-style: none;

  /* Firefox */
  scrollbar-width: none;
}

.jugl__focusable-outline {
  @apply focus-visible:outline-primary-300 focus-visible:outline focus-visible:outline-2;
}
